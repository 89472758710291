.image-preview-main-container {
    box-sizing: border-box;
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100001;
    padding: 1.25rem;
    background-color: rgba(0, 0, 0, 0.5);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.image-preview-container {
    display: flex;
    overflow: hidden;
    position: relative;
    flex-direction: column;
    width: 75%;
    height: 50%;
    border-radius: 0.75rem;
}

.image-preview-close-icon {
    position: absolute;
    top: 5px;
    right: 15px;
    z-index: 20;
    color: #ddd;
    cursor: pointer;
}

.image-preview {
    display: flex;
    overflow-y: auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.image {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

/* PDF preview container */
.pdf-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 1.5em;
}

/* PDF container */
.pdf-viewer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 1.5em;
}

/* PDF navigator page number style */
.pdf-viewer p {
    margin: 0;
    padding-top: 10px;
    color: #FFF;
}