.pre-login-section {
  background-image: url("../../images/pre-login-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  padding: 40px 24px;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  // &::after{
  //     content: '';
  //     position:fixed;
  //     top: 0;
  //     right: 0;
  //     left: 0;
  //     bottom: 0;
  //     background-color: rgba($color: $primary-color, $alpha: 0.8);
  // }
  .pre-login-wrap {
    position: relative;
    z-index: 1;
    max-width: 400px;
    width: 100%;
    margin: auto;
    &.large {
      max-width: 700px;
    }
    .logo-wrap {
      text-align: center;
      margin: 0 auto 30px;
      max-width: 159px;
      width: 100%;
      img {
        width: 100%;
      }
      span {
        color: $white;
        font-size: 28px;
        display: block;
        font-weight: 600;
      }
    }
    .btn-wrap {
      text-align: center;
      padding: 16px 0 10px;
      .btn {
        width: 100%;
      }
    }
    .links-wrap {
      a {
        display: block;
        text-align: center;
        &:not(:last-child) {
          margin: 0 0 18px;
        }
      }
    }
  }
}
