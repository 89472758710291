// colors
$white: #fff;
$black: #000;

$primary-text: #212121;
$bodyBackground: #f1f5f9;
$gray1: #cccccc;
$gray2: #efefef;
$gray3: #999999;
$gray4: #828d98;
$primary-color: #ed3c5d;
$input-border: #dddddd;
$input-autofill: #fff4f6;

$yellow: #ffc107;
$yellow2: #fff3cd;
$yellow3: #664d03;
$red: #dc3545;
$green: #28a745;
$green2: #1aae6f;
$blue: #11cdef;
$blue2: #1c8cdc;
$blue3: #384b6e;
$orange: #f6a507;
$purple: #664dc9;

$table-header-bg: #f1f5f9;

// other variables

$border-radius: 6px;

// $box-shadow:0 2px 6px rgba(47, 43, 61,.14),0 0 transparent,0 0 transparent;
$box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08),
  0px 0.5px 2px rgba(96, 97, 112, 0.16);
